require("@rails/ujs").start()
require("turbolinks").start()

require("channels")
// import 'popper.js'
// import "bootstrap"
// import 'bootstrap-icons/font/bootstrap-icons.css'
import { Carousel } from 'bootstrap';
// import Alert from 'bootstrap/js/dist/alert';
// import I18n from 'i18n-js'
// window.I18n = I18n
// import "@fortawesome/fontawesome-free/css/all"

// import '../src/stylesheets/application.scss'
const images = require.context('../images', true)

var jQuery = require('jquery')

// include jQuery in global and window scope (so you can access it globally)
// in your web browser, when you type $('.div'), it is actually refering to global.$('.div')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

import Swiper, { Navigation, Autoplay } from 'swiper';
Swiper.use([Navigation, Autoplay]);
// import Swiper from 'swiper';

// require("../packs/template/dashcore/js/jquery.easing.js");
// require("../packs/template/dashcore/js/common.js");
require("../packs/template/dashcore/js/stripe-menu.js");
// require("../packs/plugins/featherlight/featherlight.js");
// require("../packs/plugins/featherlight/featherlight.gallery.js");
// require("../packs/plugins/jquery.multi-select.min.js");
// require("../packs/plugins/cocoon.js");
// require("../packs/plugins/jquery.query-object.js");


const AOS = require('../packs/template/dashcore/js/aos.js');
const Typed = require("../packs/template/dashcore/js/typed.js");
// const BeerSlider = require('../packs/plugins/beerslider/beerslider.js').BeerSlider;

$(document).on('turbolinks:load', function() {
  initTopMenu();
  hideFlashMessage();
  initSwiper();
  initVendorSwiper();
  // loadHomeBLocks();

  AOS.init({
    offset: 100,
    duration: 1500,
    disable: "mobile"
  });

  if ($(".typed").length) {
    $(".typed").each(function(i, el) {
      var strings = $(el).data("strings");


      var typed = new Typed(".typed", {
        strings: strings,
        typeSpeed: 150,
        backDelay: 500,
        backSpeed: 50,
        loop: true
      });
    });
  }

  // $.fn.BeerSlider = function ( options ) {
  //   options = options || {};
  //   return this.each(function() {
  //     new BeerSlider(this, options);
  //   });
  // };
  // $('.beer-slider').BeerSlider({start: 40});

});



// $(document).on('click', "[data-target='#myModal']", function(ev) {
//   if ($(ev.target).hasClass( "skip-modal" )) return;
//   ev.preventDefault();
//   let target = $(this).attr("href");
//   call_modal(target, "layout=popup");
// });

function call_modal(target, data, wrapper_id=null) {
  console.log('target', target)
  var content = $("#myModal .modal-content");
  var loader = $("#myModal .loader");

  content.empty();
  $("#myModal").modal("show");
  content.load(target, data, function(response, status, xhr) {
    if ( status == "error" ) {
      var icon = '<i class="fa fa-exclamation-triangle fa-3x text-red error_icon"></i>';
      loader.find('.fa').hide();
      loader.append(icon);
    } else {
      var height = content.height();
      // loader.find('.fa').show();
      // content.css({height: 500, overflow: 'auto'});
      // init_modal_preloads();
      loader.animate({height: height}, 500, function() {
        content.css({height: '', overflow: ''});
        loader.addClass('loaded')
      })
    }
  })
}

function start_loading(element) {
  console.log('element', element);
  $('' + element + '').append("<div class='loader-overlay'><div class='loader'></div></div>");
}

function stop_loading(element) {
  $(element).children(".loader-overlay").fadeOut(800).hide(800);
}


function hideFlashMessage() {
  if ($(".flash-notification").length) {
    setTimeout(function(){ $(".flash-notification").hide(500) }, 8000);
  }
}




function initSwiper() {
  $(".swiper-container").each(function() {
    var $this = $(this);
    var boolData = {
      breakpoints: $this.data("sw-breakpoints"),
      active_selector: $this.data("sw-active-selector"),
      cover_flow: $this.data("sw-coverflow"),
      auto_play: $this.data("sw-autoplay"),
      loop: $this.data("sw-loop"),
      centered: $this.data("sw-centered-slides"),
      pagination: $this.data("sw-pagination"),
      nav_arrows: $this.data("sw-nav-arrows"),
      dynamicBullets: $this.data("sw-dynamic-bullets")
    };

    var breakPoints = boolData.breakpoints || false;
    var auto_play =
      boolData.auto_play !== undefined ? boolData.auto_play : false;
    var speed = $this.data("sw-speed") || 1100;
    var effect = $this.data("sw-effect") || "slide";
    var showItems = $this.data("sw-show-items") || 1;
    var loop = boolData.loop !== undefined ? boolData.loop : true;
    var centered = boolData.centered !== undefined ? boolData.centered : true;
    var dynamicBullets =
      boolData.dynamicBullets !== undefined ? boolData.dynamicBullets : true;
    var spaceBetween =
      $this.data("sw-space-between") || (showItems > 1 ? 20 : 0);
    var scrollItems = $this.data("sw-scroll-items") || 1;
    var navigationElement = $this.data("sw-navigation");
    var navigationActiveClass =
      $this.data("sw-navigation-active") || "active";
    var navigationActiveSelector =
      boolData.active_selector !== undefined
        ? boolData.active_selector
        : false;
    var paginationCss =
      boolData.pagination !== undefined
        ? boolData.pagination
        : ".swiper-pagination";
    var navigationCss =
      boolData.nav_arrows !== undefined
        ? boolData.nav_arrows
        : ".swiper-button";

    var coverflow = boolData.cover_flow
      ? {
          coverflowEffect: $.extend(
            {
              stretch: 0,
              depth: 0,
              modifier: 1,
              rotate: 0,
              slideShadows: false
            },
            boolData.cover_flow
          )
        }
      : {};

    var autoplay = auto_play
      ? {
          autoplay: {
            delay: auto_play,
            disableOnIteration: true
          },
          speed: speed
        }
      : {};

    var pagination = {};

    if (paginationCss) {
      pagination.pagination = {
        el: paginationCss,
        clickable: true
      };

      /* if (dynamicBullets) {
                  pagination.pagination.dynamicBullets = dynamicBullets;
              } */
    }

    if (navigationCss) {
      pagination.navigation = {
        nextEl: navigationCss + "-next",
        prevEl: navigationCss + "-prev"
      };
    }

    var events = {};

    /**/ if (navigationElement) {
      //console.log("navigationElement swiper", navigationElement);
      events = {
        transitionEnd: function() {
          if (!navigationElement) return;

          var $navigationElement = $(navigationElement);

          if (navigationActiveSelector) {
            $(
              navigationActiveSelector + "." + navigationActiveClass,
              $navigationElement
            ).removeClass(navigationActiveClass);
            $(
              ".nav-item:eq(" +
                swiper.realIndex +
                ") " +
                navigationActiveSelector,
              $navigationElement
            ).addClass(navigationActiveClass);
          } else {
            $("." + navigationActiveClass, $navigationElement).removeClass(
              navigationActiveClass
            );
            $(
              ".nav-item:eq(" + swiper.realIndex + ")",
              $navigationElement
            ).addClass(navigationActiveClass);
          }
        }
      };
    } /**/

    var options = $.extend(
      {
        loop: loop,
        slidesPerGroup: scrollItems,
        spaceBetween: spaceBetween,
        centeredSlides: centered,
        breakpoints: breakPoints,
        slidesPerView: showItems,
        parallax: true,
        effect: effect
      },
      pagination,
      autoplay,
      coverflow
    );

    console.log("swiper options", options);
    var swiper = new Swiper(this, options);

    for (var e in events) {
      swiper.on(e, events[e]);
    }

    if (navigationElement) {
      $(navigationElement).on("click", ".nav-item", function(evt) {
        evt.preventDefault();

        var $item = $(this);
        var $activeItem = $item;

        if (navigationActiveSelector) {
          $activeItem = $(navigationActiveSelector, $item);
        }

        if ($activeItem.hasClass(navigationActiveClass)) {
          return false;
        }

        var index = $item.data("step") || $item.index() + 1;
        swiper.slideTo(index);

        if (navigationActiveSelector) {
          $item.siblings().each(function() {
            $(navigationActiveSelector, this).removeClass(
              navigationActiveClass
            );
          });

          $activeItem.addClass(navigationActiveClass);
        } else {
          $item
            .siblings("." + navigationActiveClass)
            .removeClass(navigationActiveClass);
          $item.addClass(navigationActiveClass);
        }

        return false;
      });
    }
  });
}

$(document).on("click", ".cookie-accept-link", function(){
  var cookie_url = $(this).attr('data-link');
  $.ajax({
    method: 'GET',
    url: '/cookie_accept',
    success: function (status) {
      $('.footer-cookies-terms-block').remove();
    }
  });
})

function initTopMenu() {
  let $navbar = $(".main-nav"),
    stickyPoint = 90;

  /** Perspective mockups reference **/
  let $perspectiveMockups = $(".perspective-mockups");

  // This element is used as reference for relocation of the mockups on mobile devices.
  // If you remove it please be sure you add another reference element preferably within the same section and/or position the button was.
  // You can change the selector (".learn-more") to one that uniquely identifies the reference element.
  let $topReference = $(".learn-more", ".lightweight-template");

  let setMockupsTop = function() {
    // check if the perspective mockups elements are on the page, if you're not going to use them, you can remove all its references
    if (!$perspectiveMockups.length) return;

    if ($(window).outerWidth() < 768) {
      $perspectiveMockups.css({ top: $topReference.offset().top + "px" });
      return;
    }

    $perspectiveMockups.removeAttr("style");
  };

  let navbarSticky = function() {
    if ($(window).scrollTop() >= stickyPoint) {
      $navbar.addClass("navbar-sticky");
    } else {
      $navbar.removeClass("navbar-sticky");
    }
  };

  $(window).on("scroll", navbarSticky);

  navbarSticky();
}


function initVendorSwiper() {
  if ($(".mySwiper").length) {
    var swiper = new Swiper(".mySwiper", {
      spaceBetween: 35,
      simulateTouch: true,
      loop: true,
      autoplay: {
        delay: 2500
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },

       breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 1
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 2
          },
          // when window width is >= 640px
          900: {
            slidesPerView: 3
          },
          1200: {
            slidesPerView: 4
          }
        }

    });
  }
}

// function loadHomeBLocks() {
//   console.log('load');
//   if ($(".home-block:empty").length) {
//     $(".home-block:empty").each(function(i, el) {
//       console.log('a')
//       // if ($(el)){
//       //   return true;
//       // }
//       let partial = $(el).data("partial");
//       let id = $(el).attr("id");

//       $.ajax({
//         url: '/pages/load_home_block.js',
//         method: 'GET',
//         data: { id: id, partial: partial },
//         success: function (status) {
//           initSwiper();
//           initVendorSwiper();
//         },
//         cache: false
//       });

//     });
//   }
// }
